import React from 'react'

const HowItWorks = () => {
  return (
    <div className='w-full border-b-2 pb-20'>
        <h1 className="text-center py-10 2xl:py-20 mx-auto font-headingfont text-2xl md:text-3xl 2xl:text-5xl font-bold">How It Works</h1>
        <div className="flex flex-wrap gap-10 items-center justify-center">
            <div className="bg-[#FF6F61] text-white w-5/6 md:w-1/3 2xl:w-1/4 py-6 px-6 md:py-10 md:px-10 border rounded-3xl">
                <h3 className='font-headingfont font-bold text-3xl mb-4'>Overview</h3>
                <p className='font-bodyfont text-md'>The duration of each programme (2-4 weeks, etc) indicates how long the programme is expected to last. However, students may take as long as they wish since the programmes are self-paced will recorded lectures and online material.</p>
            </div>
            <div className="bg-[#FF6F61] text-white w-5/6 md:w-1/3 2xl:w-1/4 py-6 px-6 md:py-10 md:px-10 border rounded-3xl">
                <h3 className='font-headingfont font-bold text-3xl mb-4'>1 on 1</h3>
                <p className='font-bodyfont text-md'>We also offer 1 on 1 lessons for children and adults alike! To enquire about this further, <a href="/contact" className='underline font-bold'>contact us</a> with your age, programme you’re interested in, and availability times and we’ll get back to you as soon as possible!</p>
            </div>
            <div className="bg-[#FF6F61] text-white w-5/6 md:w-[calc(66.666666%+2.5rem)] 2xl:w-[calc(50%+2.5rem)] py-6 px-6 md:py-10 md:px-10 border rounded-3xl">
                <h3 className='font-headingfont font-bold text-3xl mb-4'>What to expect after enrolling</h3>
                <p className='font-bodyfont text-md'>Shortly after signing up to a programme, you will receive an email with a link to the online discussion/learning group, hosted on Discord and monitored by qualified and certified teachers. It will contain information about setting up before the first lesson, as well as what to expect in a weekly outline. Each week there will be 1-2 live lectures in which the weeks topics will be covered, and they will be recorded in case you can’t make it in time. 24/7 help will be available through the discussion group in case there are difficulties in understanding any of the content, so you won’t be left behind!</p>
            </div>
        </div>
    </div>
  )
}

export default HowItWorks
import React from 'react'

const Down = () => {
  return (
    <div>
        <p>currently down</p>
    </div>
  )
}

export default Down

import Navbar from '../components/Navbar';
import CurrentProgrammes from '../components/CurrentProgrammes';

const Programmes = () => {
    return (
        <div>
            <Navbar />
            <div className="w-full 2xl-py-8 px-10 md:px-0 ">
                <CurrentProgrammes />
            </div>
            {/* <Foot /> */}
        </div>
    );
};

export default Programmes;

import React from 'react'

const Submitted = () => {
  return (
    <div className='flex flex-col text-center items-center justify-center mt-[30vh]'>
        <h1 className='font-bold text-3xl lg:text-4xl font-titefont p-10'>Thank you for your message. We will get in touch as soon as possible.</h1>
        <a href="/" className='text-xl lg:text-2xl'>Return to Home</a>
    </div>
  )
}

export default Submitted
import React, { useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import Foot from '../components/Foot'
import emailjs from '@emailjs/browser';
const Contact = () => {

  const [buttonClicked, setButtonClicked] = useState(false);
  const form = useRef();

    const handleSubmit = async () => {
      try {
        setButtonClicked(true);
        await emailjs
        .sendForm('service_uee4b7p', 'kidzcode', form.current, {
          publicKey: 'Z8tQyLHKGH3Ygeb47',
        });
        console.log('Email sent successfully!');
        window.location.href = '/submitted';
      } catch (error) {
        console.error('Email sending failed:', error);
        setButtonClicked(false); 
      }
    };
  return (
    <div>
        <Navbar />
        <div className="w-full flex flex-col items-center justify-center py-5 md:py-10 lg:py-20 ">
          <h1 className='font-bold text-4xl lg:text-5xl font-titefont  py-10 lg:py-5'>Contact</h1>
          <form className="form  flex flex-col w-3/4 md:w-2/3 lg:w-1/3 items-center justify-center lg:py-10" ref={form}>
              <input placeholder="Name" type="text" name="from_name" 
              className="mb-2 rounded px-3 py-2 w-full border border-black text-black" />

              <input placeholder="Email" type="email" name="from_email" 
              className="mb-2 rounded px-3 py-2 w-full border border-black text-black" />

              <textarea placeholder="Message" name="message" rows="6" 
              className="mb-2 rounded px-3 py-2 w-full border border-black text-black" />

              {buttonClicked ? (
                  <h3>Sending...</h3>
              ) : (
                  <button type="submit" value="Send" onClick={handleSubmit} disabled={buttonClicked} 
                  className="bg-main-color px-4 py-2 rounded text-white border border-black hover:bg-black transition duration-300"
                  >Submit Message</button>
              )}
          </form>
          {/* <h3 className='text-center pt-5 px-4'>Alternatively, you can give us a call at <a href="tel:0411 520 712" className='underline'>0411 520 712</a> for more information!</h3> */}
        </div>
        <div className="hidden md:block">
          <Foot fixed = {true}/>
        </div>
    </div>
  )
}

export default Contact
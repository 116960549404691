import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='flex justify-between sm:p-10 sm:px-20 p-10 px-4 h-10 border-b-2 border2 items-center sticky top-0 bg-white z-10'>
      <h1 className='font-logofont text-2xl font-bold'>
        <Link to="/" className='flex items-center'>
          &#123;Kidz<span className='text-main-color'>Code&#125;</span>
        </Link>
      </h1>
      <button className='block lg:hidden' onClick={toggleMenu}>
        <svg className='w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16m-7 6h7'></path>
        </svg>
      </button>
      <ul className={`flex ${isOpen ? 'flex-col bg-gray-100 absolute top-20 left-0 w-full' : 'hidden lg:flex'}`}>
        <li className='font-navfont px-10 py-2 lg:py-0'>
          <a href="/programmes">Programmes</a>
        </li>
        <li className='font-navfont px-10 py-2 lg:py-0'>
          <a href="/about">How it works</a>
        </li>
        <li className='font-navfont px-10 py-2 lg:py-0'>
          <a href="/contact">Contact</a>
        </li>
        <li className={`${isOpen ? 'font-navfont px-10 py-2 lg:py-0' : 'hidden'}`}>
          <a href="/programmes">Register</a>
        </li>
      </ul>
      <Link className={`lg:block hidden`} to="/programmes">
        <button className={`lg:block hidden xs:hidden buttonfont px-8 py-3 rounded-3xl bg-main-color text-white`}>
          Register
        </button>
      </Link>
    </div>
  );
};

export default Navbar;

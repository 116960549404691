import React from 'react'

const Foot = ({ fixed }: { fixed: boolean }) => {
  return (
    <div className={`w-full pt-16 ${fixed ? 'fixed bottom-0' : ''}`}>
    <div className='flex items-center justify-center bg-black h-14 md:h-8'>
        <h3 className='text-white text-center'>© 2024 KidzCode.</h3>
    </div>
    </div>
  )
}

export default Foot
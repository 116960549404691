import React from 'react'
import Navbar from '../components/Navbar'
import Faqs from '../components/Faqs'
import Foot from '../components/Foot'
import HowItWorks from '../components/HowItWorks'

const About = () => {
  return (
    <div>
        <Navbar />
        <HowItWorks />
        <Faqs />
        <Foot fixed = {false}/>
    </div>
  )
}

export default About
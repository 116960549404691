import React from 'react'
import FaqItem from './FaqItem'

const Faqs = () => {

  return (
    <div className='py-20 w-ful'>
        <div className='w-5/6 mx-auto '>
            <h1 className='my-4 sm:my-10 font-headingfont text-6xl'>FAQs</h1>
            <FaqItem question='What devices can be used?' answer='Our courses are suitable for primarily computer/laptop devices (Windows and Mac), and iPads. Note that the Game Development course currently does not support iPad use. If you are unsure about whether your device is suitable for a particular course, please contact us.'/>
            <FaqItem question='Which ages are accepted?' answer='We take in ages 8 and above. For our standard classes, we typically take students from 8-18, but we also take adults for one-on-one sessions only.'/>
            <FaqItem question='What experience is required?' answer="We expect 0 prior knowledge and experience, teaching from the ground up in our beginner programmes. For intermediate programmes, we expect you to have completed the beginner version of the programme beforehand, but if you haven't and think you can take it on, go for it!"/>
            <FaqItem question='How long do courses run for?' answer="Our classes typically run for 2-4 weeks for beginner and intermediate classes for most programmes, depending on whether it's running during school times or holidays. The All-Rounder course takes around 6 weeks, but it's important to know that all programmes can be taken at a self-paced timeframe and these are just the recommended/expected times."/>
            <FaqItem question='Which courses are best for beginners?' answer="All of out programmes' beginner versions are beginner-friendly, expecting no prior knowledge whatsoever! For kids who don't know where to begin, the Coding Basics course is a great introduction to coding in general that will be useful in the future :)"/>
            <FaqItem question='What if I want to learn 1 on 1?' answer="We also offer 1 on 1 lessons for children and adults alike! To enquire about this further, contact us with your age, programme you’re interested in, and availability times and we’ll get back to you as soon as possible!"/>
        </div>
    </div>
  )
}

export default Faqs
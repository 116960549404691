import React, { useState } from 'react'

const FaqItem = ({question, answer}: {question: string, answer: string}) => {
    const [isOpen, setIsOpen] = useState(false);
      
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  return (
    <div>        
        <ul className='lg:w-1/2 w-7/8 mx-auto'>
            <li onClick={toggleDropdown} className={`cursor-pointer flex items-center text-xl md:text-2xl font-bold justify-between py-10 ${!isOpen ? "border-b border-gray-300" : "mb-0"}`}>
            <div className="font-faqfont mr-2 lg:text-2xl text-base">{question}</div>
            <button onClick={toggleDropdown} className="focus:outline-none">
                {isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                </svg>
                ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                )}
            </button>
            </li>
            <div className={`${isOpen ? 'faqtransition' : 'faqtransition1'}`}>
                {/* {isOpen && ( */}
                    <p className={`font-bodyfont pt-2 pb-12 mt-0 border-b-2 border-gray-300 ${isOpen ? 'block' : 'hidden'}`}>{answer}</p>
                {/* )} */}
            </div>
    </ul>
  </div>
  )
}

export default FaqItem